
    import { useI18next } from '@composables/i18next';
    import { openPopup } from '../popup';
    import openModal, { PrizeDetailsModal } from '../modals';

    export default {
        name: 'NavList',

        emits: ['close-list'],

        setup () {
            const { t, vT } = useI18next(['links', 'prizes']);
            return { t, vT };
        },

        methods: {
            openPopup,

            openPrizeDetails () {
                this.$emit('close-list');
                openModal(PrizeDetailsModal);
            },
        },
    };
