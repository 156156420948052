import { resolveDirective as _resolveDirective, createElementVNode as _createElementVNode, withDirectives as _withDirectives, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "footer-links" }
const _hoisted_2 = { class: "menu-list" }
const _hoisted_3 = ["title"]
const _hoisted_4 = ["title"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _directive_t = _resolveDirective("t")

  return (_openBlock(), _createElementBlock("nav", _hoisted_1, [
    _createElementVNode("ul", _hoisted_2, [
      _createElementVNode("li", null, [
        _withDirectives(_createElementVNode("button", {
          class: "unstyle",
          title: _ctx.$t('links:prizes_copy'),
          onClick: _cache[0] || (_cache[0] = (...args) => ($options.openPrizeDetails && $options.openPrizeDetails(...args)))
        }, null, 8, _hoisted_3), [
          [_directive_t, 'links:prizes_copy']
        ])
      ]),
      _withDirectives(_createElementVNode("li", null, null, 512), [
        [_directive_t, { key: 'links:rs_rules', tb2URL: _ctx.$store.state.app.tb2URL }]
      ]),
      _withDirectives(_createElementVNode("li", null, null, 512), [
        [_directive_t, 'links:privacy']
      ]),
      _withDirectives(_createElementVNode("li", null, null, 512), [
        [_directive_t, 'links:accessibility_policy']
      ]),
      _withDirectives(_createElementVNode("li", null, null, 512), [
        [_directive_t, 'links:do_not_sell']
      ]),
      _withDirectives(_createElementVNode("li", null, null, 512), [
        [_directive_t, 'links:cookies']
      ]),
      _withDirectives(_createElementVNode("li", null, null, 512), [
        [_directive_t, 'links:terms']
      ]),
      _createElementVNode("li", null, [
        _withDirectives(_createElementVNode("a", {
          href: "/faq",
          title: `${_ctx.$t('links:faq_copy')} ${_ctx.$t('links:popup')}`,
          onClick: _cache[1] || (_cache[1] = _withModifiers((...args) => ($options.openPopup && $options.openPopup(...args)), ["prevent"]))
        }, null, 8, _hoisted_4), [
          [_directive_t, 'links:faq_copy']
        ])
      ]),
      _withDirectives(_createElementVNode("li", null, null, 512), [
        [_directive_t, 'links:snackworks']
      ])
    ])
  ]))
}