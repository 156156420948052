import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "wrapper"
}
const _hoisted_2 = {
  id: "main-section",
  role: "main"
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_TheHeader = _resolveComponent("TheHeader")
  const _component_TheMenu = _resolveComponent("TheMenu")
  const _component_router_view = _resolveComponent("router-view")
  const _component_TheFooter = _resolveComponent("TheFooter")

  return (_ctx.app.phase != 'pre-launch')
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.$store.state.ui.rapidTemplate ? [
                'rapid-template',
                `rapid-template-${_ctx.$store.state.ui.rapidTemplate}`,
            ] : [])
        }, [
          (_ctx.ui.pageName != 'faq')
            ? (_openBlock(), _createBlock(_component_TheHeader, { key: 0 }))
            : _createCommentVNode("", true),
          (_ctx.ui.pageName != 'faq')
            ? (_openBlock(), _createBlock(_component_TheMenu, { key: 1 }))
            : _createCommentVNode("", true),
          _createElementVNode("main", _hoisted_2, [
            _createVNode(_component_router_view, { class: "content" })
          ])
        ], 2),
        (_ctx.ui.pageName != 'faq')
          ? (_openBlock(), _createBlock(_component_TheFooter, { key: 0 }))
          : _createCommentVNode("", true)
      ]))
    : _createCommentVNode("", true)
}