import store from '@public/js/store';

// Asynchronous component definitions for lazy-loading routes.
// https://v3.router.vuejs.org/guide/advanced/lazy-loading.html
// https://webpack.js.org/guides/code-splitting/#dynamic-imports
// https://webpack.js.org/api/module-methods/#magic-comments
const
    GamePage = async () => import(
        /* webpackChunkName: 'game' */
        '../views/GamePage.vue'
    ),
    GameLoaderPage = async () => import(
        /* webpackChunkName: 'game' */
        '../views/GameLoaderPage.vue'
    ),
    InkyPage = async () => import(
        /* webpackChunkName: 'game' */
        '../views/InkyPage.vue'
    ),
    JankyPage = async () => import(
        /* webpackChunkName: 'game' */
        '../views/JankyPage.vue'
    );

/// //////
// helper functions
const canPlayCheck = (next) => {
    if (store.state.award?.awards?.isLimited) {
        return next('/limiter');
    }
    return next();
};

const getResults = ({
    path,
    next,
    isDefault = false,
}) => {
    const smartNext = (pathToGoTo) => {
        if (!isDefault) {
            // if pathToGoTo == path, then next() allows that pathToGoTo to render rather than redirect
            // (aka, if you don't do this, it'll cause an infinite render loop)
            if (pathToGoTo === path) {
                return next();
            }
            else {
                return next(pathToGoTo);
            }
        }
        return next(pathToGoTo);
    };

    // if the /play endpoint has been called
    if (store.state.award.hasSavedGameResults) {
        const { prizeWon } = store.state.award;
        if (prizeWon) {
            return smartNext('/inky');
        }
        return smartNext('/janky');
    }
    // else, if the user has been flagged as limited
    else if (store.state.award.isIwLimited) {
        return smartNext('/limiter');
    }
    // otherwise, not sure how you got here except by url nav, so push them all the way back to game
    return smartNext('/game');
};

/// //////
// actual routes
export default [
    { path: '/game',  component: GamePage, beforeEnter: (to, from, next) => canPlayCheck(next) },
    { path: '/play',  component: GameLoaderPage, beforeEnter: (to, from, next) => canPlayCheck(next) },
    // (fall24 also uses getResults, please maintain both!)
    { path: '/results', redirect: () => getResults({
        path: '/results',
        next: (str) => str,
        isDefault: true,
    }) },
    { path: '/inky',  component: InkyPage, beforeEnter: (to, from, next) => getResults({
        path: '/inky',
        next,
    }) },
    { path: '/janky', component: JankyPage, beforeEnter: (to, from, next) => getResults({
        path: '/janky',
        next,
    }) },
];
