import { createElementVNode as _createElementVNode, withModifiers as _withModifiers, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "menu" }
const _hoisted_2 = {
  ref: "menu",
  class: "menu-dialog"
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_NavList = _resolveComponent("NavList")

  return (_openBlock(), _createElementBlock("nav", _hoisted_1, [
    _createElementVNode("button", {
      class: "menu-button-open small-only",
      title: "Open Menu",
      onClick: _cache[0] || (_cache[0] = _withModifiers($event => ($options.openMenu()), ["prevent"]))
    }, _cache[3] || (_cache[3] = [
      _createElementVNode("i", { class: "fas fa-bars" }, null, -1)
    ])),
    _createElementVNode("dialog", _hoisted_2, [
      _createVNode(_component_NavList, {
        onCloseList: _cache[1] || (_cache[1] = $event => ($options.closeMenu()))
      }),
      _createElementVNode("button", {
        class: "menu-button-close",
        title: "Close Menu",
        onClick: _cache[2] || (_cache[2] = _withModifiers($event => ($options.closeMenu()), ["prevent"]))
      }, _cache[4] || (_cache[4] = [
        _createElementVNode("svg", {
          alt: "X",
          class: "menu-x",
          xmlns: "http://www.w3.org/2000/svg",
          viewBox: "0 0 384 512"
        }, [
          _createElementVNode("path", {
            fill: "#ffffff",
            d: "M376.6 84.5c11.3-13.6 9.5-33.8-4.1-45.1s-33.8-9.5-45.1 4.1L192 206 56.6 43.5C45.3 29.9 25.1 28.1 11.5 39.4S-3.9 70.9 7.4 84.5L150.3 256 7.4 427.5c-11.3 13.6-9.5 33.8 4.1 45.1s33.8 9.5 45.1-4.1L192 306 327.4 468.5c11.3 13.6 31.5 15.4 45.1 4.1s15.4-31.5 4.1-45.1L233.7 256 376.6 84.5z"
          })
        ], -1)
      ]))
    ], 512)
  ]))
}