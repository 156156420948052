import { resolveDirective as _resolveDirective, createElementVNode as _createElementVNode, withDirectives as _withDirectives, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "modal",
  role: "dialog"
}
const _hoisted_2 = ["src"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _directive_t = _resolveDirective("t")

  return (_openBlock(), _createElementBlock("div", {
    class: "modal-overlay",
    onClick: _cache[4] || (_cache[4] = _withModifiers($event => (_ctx.closeModal()), ["self"]))
  }, [
    _createElementVNode("div", _hoisted_1, [
      _withDirectives(_createElementVNode("h2", null, null, 512), [
        [_directive_t, `card_share:card_share.headline_copy`]
      ]),
      _createElementVNode("img", {
        alt: "",
        class: "modal-card-image",
        src: `https://eprize-content.s3.amazonaws.com/mondelez/nabiscoholiday24/cards/336/${$props.card}.jpg`
      }, null, 8, _hoisted_2),
      _withDirectives(_createElementVNode("p", null, null, 512), [
        [_directive_t, `card_share:card_share.body_copy${_ctx.profile.bonusSweepsEarned ? '' : '_earned'}`]
      ]),
      _withDirectives(_createElementVNode("button", {
        class: "modal-button",
        onClick: _cache[0] || (_cache[0] = (...args) => ($options.updateCardAndContinue && $options.updateCardAndContinue(...args)))
      }, null, 512), [
        [_directive_t, 'buttons:continue']
      ]),
      _withDirectives(_createElementVNode("button", {
        class: "modal-button",
        onClick: _cache[1] || (_cache[1] = (...args) => ($options.print && $options.print(...args)))
      }, null, 512), [
        [_directive_t, 'buttons:print']
      ]),
      _withDirectives(_createElementVNode("button", {
        class: "link",
        title: "Skip",
        onClick: _cache[2] || (_cache[2] = (...args) => ($options.skip && $options.skip(...args)))
      }, null, 512), [
        [_directive_t, 'buttons:skip']
      ]),
      _createElementVNode("button", {
        class: "unstyle modal-x",
        title: "Close",
        onClick: _cache[3] || (_cache[3] = _withModifiers($event => (_ctx.closeModal()), ["prevent"]))
      }, " X ")
    ])
  ]))
}