
    import BaseModal from './BaseModal';

    export default {
        name: 'RelationshipModal',

        extends: BaseModal,

        data () {
            return {
                modalName: 'relationship',
            };
        },
    };
