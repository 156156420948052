
    import { mapState } from 'vuex';
    import BaseModal from './BaseModal';

    export default {
        name: 'CardModal',

        extends: BaseModal,

        props: {
            card: {
                type: Number,
                default: 1,
                required: true,
            },
        },

        data () {
            return {
                modalName: 'card',
            };
        },

        computed: {
            ...mapState(['profile']),
            imageToPrint () {
                return '<html><head><scri' + 'pt>function step1(){\n' +
                    'setTimeout("step2()", 10);}\n' +
                    'function step2(){window.print();window.close()}\n' +
                    '</scri' + 'pt></head><body onload="step1()">\n' +
                    '<img src="https://eprize-content.s3.amazonaws.com/mondelez/nabiscoholiday24/cards/' + this.card + '.jpg" style="max-height: 900px;" /></body></html>';
            },
        },

        methods: {
            print () {
                const pwa = window.open('about:blank', '_blank');
                pwa.document.open();
                pwa.document.write(this.imageToPrint);
                pwa.document.close();
            },

            async updateCardAndContinue () {
                this.closeModal('/share');
            },
            skip () {
                this.closeModal('/skip-card');
            },
        },
    };
