
import axios from 'axios';
import { reactive } from 'vue';
import api from '../../api';
import { setRecaptchaV3Challenge } from '../../recaptcha';

const getDefaultState = () => reactive({
    awards: null,
    prizeWon: null,
    isIwLimited: false,
    isCardShareSweepsLimited: false,
    hasSavedGameResults: false,
});

const state = getDefaultState();

const getters = {
    uuid: (state) => (state.prizeWon?.id || '').replaceAll('-', '').toLowerCase(),
};

const mutations = {
    updateAwards (state, awards) {
        state.awards = awards;
    },
    updatePrizeWon (state, prizeWon) {
        state.prizeWon = prizeWon;
    },
    updateActivitiesState (state, events) {
        if (events.iw_game) state.isIwLimited = events.iw_game.isLimited;
        if (events.referral) state.isCardShareSweepsLimited = events.referral.isLimited;
    },
    updateField (state, keyValFieldValObject) {
        const fieldsToUpdate = Object.keys(keyValFieldValObject);
        fieldsToUpdate.forEach(field => state[field] = keyValFieldValObject[field]);
    },
};

const actions = {
    async getAwards ({ dispatch }) {
        return dispatch('makeCall', {
            type: 'get',
            endpoint: 'awards',
        });
    },

    async awardEvent ({ dispatch }, { event }) {
        const data = {};
        await setRecaptchaV3Challenge({ data, action: 'award' });
        return dispatch('makeCall', {
            endpoint: `awards/events/${encodeURIComponent(event)}:award`,
            data,
        });
    },

    async activitiesState ({ dispatch }, { events = ['iw_game', 'referral'] }) {
        return dispatch('makeCall', {
            endpoint: 'awards/events-state',
            data: { events },
        });
    },

    async play ({ commit, dispatch, state }) {
        commit('updateField', { hasSavedGameResults: true });
        const data = {};
        await setRecaptchaV3Challenge({ data, action: 'play' });
        await dispatch('makeCall', {
            endpoint: 'awards/:play',
            data,
        });
        return state.prizeWon;
    },

    async makeCall ({ commit }, {
        type = 'post',
        endpoint,
        data,
    }) {
        let response;

        try {
            response = await axios[type](`${api.base}/${endpoint}`, data);
        }
        catch (err) {
            if (err.response?.status === 429) {
                // User was only limited, carry on
                ({ response } = err);
            }
            else {
                console.error(
                    `error making ${endpoint} call`,
                    err.message,
                    err,
                );

                throw err;
            }
        }

        if (response.data?.awards !== undefined) {
            commit('updateAwards', response.data.awards);
        }
        if (response.data?.prizeWon !== undefined) {
            commit('updatePrizeWon', response.data.prizeWon);
        }
        if (response.data?.events !== undefined) {
            commit('updateActivitiesState', response.data.events);
        }

        return response;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
