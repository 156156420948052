import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode } from "vue"
import _imports_0 from '@public/img/header-sans-cookies.png'
import _imports_1 from '@public/img/header-sans-cookies-lg.png'
import _imports_2 from '@public/img/header_short.png'
import _imports_3 from '@public/img/header-sans-centered-letter-lg.png'
import _imports_4 from '@public/img/header.png'
import _imports_5 from '@public/img/header_lg.png'


const _hoisted_1 = {
  id: "header",
  role: "banner",
  "data-e2e": "global-header"
}
const _hoisted_2 = ["src"]
const _hoisted_3 = ["src"]

import { ref, onMounted, computed } from 'vue';
    import { useStore } from 'vuex';
    import { useI18next } from '@composables/i18next';

    
export default {
  __name: 'TheHeader',
  setup(__props) {

    const { t } = useI18next(['global']);
    const store = useStore();

    const ui = computed(() => store.state.ui);

    // data
    const h1 = ref(null);

    const cookies = [
        'triscuit',
        'ritz',
        'chipsahoy',
        'oreo',
    ];

    // Focus the h1 element to force screen readers back to the top after a navigation.
    // This will ensure screen readers will read the page again.
    // https://a11y-guidelines.orange.com/en/articles/single-page-app/#notify-user-of-page-changes
    onMounted(() => h1.value.focus());

return (_ctx, _cache) => {
  return (_openBlock(), _createElementBlock("header", _hoisted_1, [
    _createElementVNode("h1", {
      ref_key: "h1",
      ref: h1,
      tabindex: "-1"
    }, _toDisplayString(_unref(t)('global:title')), 513),
    (['register', 'login'].includes(ui.value.pageName))
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          _cache[0] || (_cache[0] = _createElementVNode("img", {
            alt: "Send Holiday greets-n-treats",
            class: "header-img small-only",
            src: _imports_0
          }, null, -1)),
          _cache[1] || (_cache[1] = _createElementVNode("img", {
            alt: "Send Holiday greets-n-treats",
            class: "header-img large-only",
            src: _imports_1
          }, null, -1)),
          (_openBlock(), _createElementBlock(_Fragment, null, _renderList(cookies, (cookie) => {
            return _createElementVNode("div", {
              key: cookie,
              class: _normalizeClass(`ani_pulse animate__infinite header-cookie header-${cookie}`)
            }, [
              _createElementVNode("img", {
                alt: "",
                class: "small-only",
                src: `public/img/header-${cookie}.png`
              }, null, 8, _hoisted_2),
              _createElementVNode("img", {
                alt: "",
                class: "large-only",
                src: `public/img/header-${cookie}-lg.png`
              }, null, 8, _hoisted_3)
            ], 2)
          }), 64))
        ], 64))
      : (_unref(store).state.ui.pageName === 'inky' || _unref(store).state.ui.pageName === 'janky')
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
            _cache[2] || (_cache[2] = _createElementVNode("img", {
              src: _imports_2,
              alt: "Send Holiday greets-n-treats",
              class: "header-img small-only"
            }, null, -1)),
            _cache[3] || (_cache[3] = _createElementVNode("img", {
              src: _imports_3,
              alt: "Send Holiday greets-n-treats",
              class: "header-img large-only"
            }, null, -1))
          ], 64))
        : (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
            _cache[4] || (_cache[4] = _createElementVNode("img", {
              src: _imports_4,
              alt: "Send Holiday greets-n-treats",
              class: "header-img small-only"
            }, null, -1)),
            _cache[5] || (_cache[5] = _createElementVNode("img", {
              src: _imports_5,
              alt: "Send Holiday greets-n-treats",
              class: "header-img large-only"
            }, null, -1))
          ], 64))
  ]))
}
}

}