import { resolveDirective as _resolveDirective, createElementVNode as _createElementVNode, withDirectives as _withDirectives, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "modal",
  role: "dialog"
}
const _hoisted_2 = { class: "m60 d45" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _directive_t = _resolveDirective("t")

  return (_openBlock(), _createElementBlock("div", {
    class: "modal-overlay",
    onClick: _cache[1] || (_cache[1] = _withModifiers($event => (_ctx.closeModal()), ["self"]))
  }, [
    _createElementVNode("div", _hoisted_1, [
      _withDirectives(_createElementVNode("h2", _hoisted_2, null, 512), [
        [_directive_t, 'prizes:headline_copy']
      ]),
      _withDirectives(_createElementVNode("div", null, null, 512), [
        [_directive_t, { key: 'prizes:body_copy', tb2URL: _ctx.$store.state.app.tb2URL }]
      ]),
      _createElementVNode("button", {
        class: "button",
        "data-e2e": "error-modal-close",
        onClick: _cache[0] || (_cache[0] = _withModifiers($event => (_ctx.closeModal()), ["prevent"]))
      }, " Close ")
    ])
  ]))
}