
    import BaseModal from './BaseModal';

    export default {
        name: 'PrizeDetailsModal',

        extends: BaseModal,

        data () {
            return {
                modalName: 'error',
            };
        },
    };
