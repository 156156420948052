
const enabled = false;
const variant = "a";

const getDefaultState = () => ({
    pageName: 'default',
    pageClasses: [],
    modalName: null,
    rapidTemplate: enabled && variant,
    showBonusSweepsDisclaimer: false,
});

const state = getDefaultState();

const getters = {
    // convenience getter for elements that need to know when modals are open/closed
    // such as adding dynamic attributes to help create modal locks (inert, aria-hidden, etc).
    // ex: :aria-hidden="String(this.$store.getters['ui/isModalOpen'])" vs
    // :aria-hidden="String(!!this.$store.state.ui.modalName)"
    isModalOpen: (state) => !!state.modalName,
};

const { body } = document;
const LOADING_CLASS = 'page-loading';

const mutations = {
    pageEnter (state, newPage) {
        if (state?.pageClasses.length) {
            body.classList.remove(...state.pageClasses);
        }

        state.pageName = newPage.pageName || newPage.$options.name;
        state.pageClasses = newPage.pageClasses;

        body.id = state.pageName;

        if (body.classList.contains(LOADING_CLASS)) {
            body.classList.remove(LOADING_CLASS);
        }
        if (state?.pageClasses.length) {
            body.classList.add(...state.pageClasses);
        }
    },
    pageLeave (state) {
        body.classList.add(LOADING_CLASS);
    },

    modalOpen (state, newModal) {
        state.modalName = newModal.modalName;
    },
    modalClose (state) {
        state.modalName = null;
    },
    showBonusSweepsDisclaimer (state) {
        state.showBonusSweepsDisclaimer = true;
    },
    removeBonusSweepsDisclaimer (state) {
        state.showBonusSweepsDisclaimer = false;
    },
};

const actions = {
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
