import { createElementVNode as _createElementVNode, unref as _unref, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createTextVNode as _createTextVNode } from "vue"
import _imports_0 from '@public/img/footer_logo.png'
import _imports_1 from '@public/img/mondelez_logo.png'


const _hoisted_1 = { class: "footer" }
const _hoisted_2 = {
  key: 0,
  class: "disclaimer"
}
const _hoisted_3 = { class: "disclaimer" }

import { computed } from 'vue';
    import { useI18next } from '@composables/i18next';
    import { useStore } from 'vuex';
    import NavList from '@components/NavList.vue';
    import { openPopup } from '../popup';

    

    const __default__ = {
        components: {
            NavList,
        },
    };


export default /*@__PURE__*/Object.assign(__default__, {
  __name: 'TheFooter',
  setup(__props) {

    const store = useStore();

    const { t, vT } = useI18next(['footer', 'links', 'global']);

    const profile = computed(() => store.state.profile);
    const app = computed(() => store.state.app);
    const ui = computed(() => store.state.ui);
    const loggedIn = computed(() => store.getters['profile/loggedIn']);

    const logOut = () => store.dispatch('profile/logOut');

return (_ctx, _cache) => {
  return (_openBlock(), _createElementBlock("footer", _hoisted_1, [
    _cache[0] || (_cache[0] = _createElementVNode("div", { class: "footer-divider" }, [
      _createElementVNode("img", {
        src: _imports_0,
        alt: "Ritz, Chips Ahoy, Oreo, Triscuit, Honey Maid",
        class: "footer-img"
      })
    ], -1)),
    (ui.value.showBonusSweepsDisclaimer)
      ? _withDirectives((_openBlock(), _createElementBlock("p", _hoisted_2, null, 512)), [
          [_unref(vT), { key: 'footer:disclaimer_optout', tb2URL: app.value.tb2URL }]
        ])
      : _createCommentVNode("", true),
    _createVNode(NavList, { class: "large-only" }),
    _cache[1] || (_cache[1] = _createElementVNode("p", { class: "disclaimer" }, [
      _createTextVNode(" This site is protected by reCAPTCHA and the "),
      _createElementVNode("br", { class: "small-only" }),
      _createElementVNode("a", {
        href: "https://policies.google.com/privacy",
        target: "_blank"
      }, "Google Privacy Policy"),
      _createTextVNode(" and "),
      _createElementVNode("a", {
        href: "https://policies.google.com/terms",
        target: "_blank"
      }, "Terms of Service"),
      _createTextVNode(" apply. ")
    ], -1)),
    _withDirectives(_createElementVNode("p", _hoisted_3, null, 512), [
      [_unref(vT), { key: 'footer:disclaimer', tb2URL: app.value.tb2URL }]
    ]),
    _cache[2] || (_cache[2] = _createElementVNode("img", {
      src: _imports_1,
      alt: "Mondelez International",
      class: "mondelez-logo--img"
    }, null, -1))
  ]))
}
}

})