
import axios from 'axios';
import { reactive } from 'vue';
import api from '../../api';
import { setRecaptchaV3Challenge } from '../../recaptcha';

const getDefaultState = () => reactive({
    // sweeps earned from sharing ecards
    // 0 if the user was limited from earning
    referralSweepsEarned: 0,
    // sweeps earned for first-time sharers who
    // opted in.
    bonusSweepsEarned: 0,
    facebookShareUrl: '',
    xShareUrl: '',
    cardType: null, // this allows the site to auto generate a cardType if user is bookmarked (no default, always random, unless they specifically asked for something via the carousel click)
});

const state = getDefaultState();

const getters = {};

const mutations = {
    updateReferralCount (state, count) {
        state.referralCount = count;
    },
    updateSweepsEarned (state, {
        referralSweepsEarned = 0,
        bonusSweepsEarned = 0,
    } = {}) {
        state.referralSweepsEarned = referralSweepsEarned;
        state.bonusSweepsEarned = bonusSweepsEarned;
    },
    updateSocialShareUrls (state, { xShareUrl, facebookShareUrl }) {
        state.xShareUrl = xShareUrl;
        state.facebookShareUrl = facebookShareUrl;
    },
    updateField (state, keyValFieldValObject) {
        const fieldsToUpdate = Object.keys(keyValFieldValObject);
        fieldsToUpdate.forEach(field => state[field] = keyValFieldValObject[field]);
    },
};

const actions = {
    resetSocialShareUrls ({ commit }) {
        // reset share url so default url can use the one stored in profile
        // (see SocialShare.vue's computed urls)
        commit('updateSocialShareUrls', {
            xShareUrl: '',
            facebookShareUrl: '',
        });
    },

    async setECard ({ commit }, cardTypeObject) {
        return commit('updateField', cardTypeObject);
    },

    async sendECard ({ dispatch, commit, state, rootState }, { formData }) {
        const data = {};
        await setRecaptchaV3Challenge({ data, action: 'ecard' });
        const finalData = {
            primary_opt_in: rootState.profile.primary_opt_in,
            ...formData,
            ...data,
            card_id: `card_${state.cardType}`,
        };

        const response = await dispatch('makeCall', {
            endpoint: 'viral/:ecard',
            data: finalData,
        });

        if (response.data.result.bonusSweepsEarned != '0') {
            // sweepsEarned vs limited
            await commit('profile/updateProfile', { bonusSweepsEarned: true, primary_opt_in: true }, { root: true });
        }
    },

    async makeCall ({ commit }, {
        type = 'post',
        endpoint,
        data,
    }) {
        let response;

        try {
            response = await axios[type](`${api.base}/${endpoint}`, data);
        }
        catch (err) {
            console.error(
                `error making ${endpoint} call`,
                err.message,
                err,
            );

            throw err;
        }

        const { result } = response.data || {};

        if (result?.referralCount !== undefined) {
            commit('updateReferralCount', result.referralCount);
        }
        if (
            result?.referralSweepsEarned !== undefined ||
            result?.bonusSweepsEarned !== undefined
        ) {
            commit('updateSweepsEarned', result);
        }

        if (
            result?.facebookShareUrl !== undefined ||
            result?.xShareUrl !== undefined
        ) {
            commit('updateSocialShareUrls', result);
        }

        return response;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
